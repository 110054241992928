<template>
  <el-main>
    <div class="top">
      <div class="title">{{ dataDetail.title }}</div>
      <el-button class="returnPrev" @click="$router.go(-1)">返回</el-button>
    </div>
    <div class="time">{{ getDateformat(dataDetail.create_time) }}</div>
    <div class="content" v-html="dataDetail.content"></div>
  </el-main>
</template>

<script>
import { getDateformat } from '@/util/getDate'
export default {
  data() {
    return {
      dataDetail: null,
      getDateformat
    }
  },
  created() {
    this.dataDetail = this.$route.query
  }
}
</script>

<style lang="less" scoped>
.el-main {
  background-color: #ffff;
  padding-left: 30px;
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      font-size: 18px;
      font-weight: bold;
      position: relative;
      &::before {
        content: '';
        display: inline-block;
        width: 4px;
        height: 20px;
        background-color: #1467ff;
        position: absolute;
        top: 0;
        left: -10px;
      }
    }
  }

  .time {
    font-size: 12px;
    margin-bottom: 20px;
  }
}
</style>
